
// 禁用浏览器滚动，允许盒子滚动，防止微信浏览器下拉黑屏
let scrollView = [
	'#app',
	'.product-item'
]
var touchstartY;
document.body.addEventListener(
	"touchstart",
	function (event) {
		var events = event.touches[0] || event;
		touchstartY = events.clientY; //获取触摸目标在视口中的y坐标
	},
	false
);

document.body.addEventListener(
	"touchmove",
	function (event) {
		var events = event.touches[0] || event;
		var scrollTop = document.documentElement.scrollTop || window.scrollY
		for (let i = 0; i < scrollView.length; i++) {
			if (scrollTop) break;
			const els = document.querySelectorAll(scrollView[i]);
			scrollTop = scrollTop || 0
			els.forEach(el => scrollTop = scrollTop || el?.scrollTop)
		}
		if (
			events.clientY > touchstartY &&
			scrollTop === 0 &&
			event.cancelable
		) {
			event.preventDefault(); //禁止到顶下拉
		}
		touchstartY = events.clientY
	},
	{ passive: false }
);

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'

import './css/style.css'
import './fonts/iconfont.css'
import './css/swiper.min.css'

import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper)
import modal from './js/modal.js'

// modal的方法
Vue.prototype.$modal = modal

Vue.config.productionTip = false

new Vue({
	router,
	render: h => h(App)
}).$mount('#app')

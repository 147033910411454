import axios from 'axios'
export const anim = {
	data() {
		return {
			get_data: false // 判断是否加载过数据
		}
	},
	watch: {
		navIndex: function (val) {
			let _this = this,
				index = _this.anim_now

			if (val === index && !this.get_data) {
				this.pageData()
			}
		}
	},
	methods: {
		// 执行动画条件(val为当前页数)(index为需要执行的页数)(time为执行动画延迟多久)
		animCreate: function (val, index, time) {
			clearInterval(this.time)
			this.time = null

			if (val < index) {
				this.time = setInterval(() => { this.hide() }, time)
			} else {
				this.time = setInterval(() => { this.show() }, time)
			}
		},

		// 显示动画
		show: function () {
			let list = this.anim_list,
				len = list.length,
				index = this.anim_index

			if (this.anim_index >= len) {
				if (this.time) {
					clearInterval(this.time)
					this.time = null
				}
				return
			}

			list[index].dom.classList.add(list[index].class)
			this.anim_index += 1
		},

		// 隐藏动画
		hide: function () {
			let list = this.anim_list

			if (this.anim_index <= 0) {
				if (this.time) {
					clearInterval(this.time)
					this.time = null
				}
				return
			}

			this.anim_index -= 1
			let index = this.anim_index
			list[index].dom.classList.remove(list[index].class)
		}
	}
}

export const api = {
	data() {
		return {
			api_url: 'api/v1/resume/',
			localhost: 'https://resume.wangerdi.cn/'
		}
	},
	methods: {
		request({ method = 'get', prefixUrl, url, data = {}, success, fail }) {
			let _this = this

			axios({
				method: method || 'get',
				url: (prefixUrl || this.localhost + this.api_url) + url,
				data: data,
				dataType: 'json',
				headers: {
					'Content-Type': 'application/json;charset=UTF-8'
				}
			}).then(function (res) {
				if (res.data.code === 200) {
					success && success(res.data.data, res.data.message || '')
				} else {
					_this.$modal.showModal({ text: res.data.message })
					fail && fail()
				}
			}).catch(function (error) {
				console.log(error)
				fail && fail()
			})
		}
	}
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-skill"},[_c('div',{staticClass:"justify-center skill-container"},[_c('div',{staticClass:"skill-circle"},[_c('ul',{staticClass:"outView"},[_c('div',{staticClass:"outCircle"}),_c('div',_vm._l((_vm.out_skill),function(item,index){return _c('li',{key:index,staticClass:"out",style:({
        animationDelay:-(index/_vm.out_skill.length*_vm.out_duration)+'s',
        animationDuration: _vm.out_duration+'s',})},[_c('span',{staticClass:"out-item justify-center",style:({
            backgroundColor:item.color,
            animationDelay:-(index/_vm.out_skill.length*_vm.out_duration)+'s',
            animationDuration: _vm.out_duration+'s',}),domProps:{"innerHTML":_vm._s(item.text)}})])}),0)]),_c('ul',{staticClass:"innerView"},[_c('div',{staticClass:"innerCircle"}),_c('div',_vm._l((_vm.inner_skill),function(item,index){return _c('li',{key:index,staticClass:"inner",style:({
        animationDelay:-(index/_vm.inner_skill.length*_vm.inner_duration)+'s',
        animationDuration: _vm.inner_duration+'s',})},[_c('span',{staticClass:"inner-item justify-center",style:({
            backgroundColor:item.color,
            animationDelay:-(index/_vm.inner_skill.length*_vm.inner_duration)+'s',
            animationDuration: _vm.inner_duration+'s',}),domProps:{"innerHTML":_vm._s(item.text)}})])}),0)])]),_c('div',{staticClass:"text-view"},_vm._l((_vm.text),function(item,index){return _c('div',{key:index,staticClass:"text"},[_vm._v(_vm._s(item))])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }
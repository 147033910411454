<template>
    <div id="app">
        <router-view />
        <Modal
            :show="modal_show"
            :title="modal_title"
            :text="modal_text"
            :noCancel="modal_noCancel"
            :cancelText="modal_cancelText"
            :confirmText="modal_confirmText"
            :stopCover="modal_stopCover"
        />
    </div>
</template>

<script>
import { api } from "@/js/mixins";
import Event from "@/core/Event.js";
import Modal from "@/components/Modal";
export default {
    mixins: [api],
    name: "App",
    data() {
        return {
            modal_show: false,
            modal_title: "提示",
            modal_text: "",
            modal_noCancel: true,
            modal_cancelText: "取消",
            modal_confirmText: "确定",
            modal_stopCover: false,
        };
    },
    components: {
        Modal,
    },
    created() {
        Event.$on(
            "modal/show",
            ({
                title = "提示",
                text,
                noCancel = true,
                cancelText = "取消",
                confirmText = "确定",
                stopCover = false,
                success,
                fail,
                complete,
            }) => {
                this.modal_show = true;
                this.modal_title = title;
                this.modal_text = text;
                this.modal_noCancel = noCancel;
                this.modal_cancelText = cancelText;
                this.modal_confirmText = confirmText;
                this.modal_stopCover = stopCover;

                Event.$once("modal/confirm", () => {
                    success && success();
                });
                Event.$once("modal/cancel", () => {
                    fail && fail();
                });
                Event.$once("modal/complete", () => {
                    this.modal_show = false;
                    complete && complete();
                });
            }
        );

        var _this = this;
        // 适应h5+热更新
        document.addEventListener(
            "plusready",
            function () {
                plus.runtime.getProperty(plus.runtime.appid, function (inf) {
                    // 当前版本
                    var wgtVersion = inf.version;
                    // this.request({
                    //     url: "info/version",
                    //     data: {},
                    //     success: function (res) {
                    var data = { version: "1.0.0" };
                    if (checkPlugin(data.version, wgtVersion)) {
                        _this.$modal.showModal({
                            text: "最新版本为" + data.version,
                            success: function () {
                                downloadWgt(); // 下载wgt方法
                            },
                        });
                    }
                    //     },
                    // });
                });
            },
            false
        );

        // 下载wgt方法
        function downloadWgt() {
            var that = this;
            // 更新文件 wgt 文件地址
            var wgtUrl = "https://www.wangerdi.cn/H51632DBF.wgt";
            plus.nativeUI.showWaiting("正在更新...");
            plus.downloader
                .createDownload(wgtUrl, { filename: "_doc/update/" }, function (
                    d,
                    status
                ) {
                    if (status == 200) {
                        console.log("下载wgt成功：" + d.filename);
                        installWgt(d.filename); // 安装wgt方法
                    } else {
                        plus.nativeUI.alert("下载wgt失败！");
                    }
                    plus.nativeUI.closeWaiting();
                })
                .start();
        }
        // 安装wgt方法
        function installWgt(path) {
            plus.nativeUI.showWaiting("安装wgt文件...");
            plus.runtime.install(
                path,
                {},
                function () {
                    plus.nativeUI.closeWaiting();
                    plus.nativeUI.alert("应用资源更新完成！", function () {
                        plus.runtime.restart();
                    });
                },
                function (e) {
                    plus.nativeUI.closeWaiting();
                    plus.nativeUI.alert(
                        "安装wgt文件失败[" + e.code + "]：" + e.message
                    );
                }
            );
        }
        // 判断版本号
        function checkPlugin(a, b) {
            var a = toNum(a);
            var b = toNum(b);
            if (a == b) {
                console.log("版本号相同！版本号为:" + a);
                return false;
            } else if (a > b) {
                console.log("版本号" + a + "是新版本!");
                return true;
            } else {
                console.log("版本号" + b + "是新版本!");
                return false;
            }
        }
        //计算版本号大小,转化大小
        function toNum(a) {
            var a = a.toString();
            var c = a.split(".");
            var num_place = ["", "0", "00", "000", "0000"],
                r = num_place.reverse();
            for (var i = 0; i < c.length; i++) {
                var len = c[i].length;
                c[i] = r[len] + c[i];
            }
            var res = c.join("");
            return res;
        }
    },
};
</script>

<style>
</style>
